import { FeatureIdEnum } from '../../../../../config/FeatureEnums';

const LowThreshold = (id, value) => {
    const idMapping = {
        [FeatureIdEnum.Cartridge_Threshold_Black]: 1,
        [FeatureIdEnum.Cartridge_Threshold_Cyan]: 2,
        [FeatureIdEnum.Cartridge_Threshold_Magenta]: 3,
        [FeatureIdEnum.Cartridge_Threshold_Yellow]: 4,
        [FeatureIdEnum.Drum_Threshold_Black]: 5,
        [FeatureIdEnum.Drum_Threshold_Cyan]: 6,
        [FeatureIdEnum.Drum_Threshold_Magenta]: 7,
        [FeatureIdEnum.Drum_Threshold_Yellow]: 8,
    };

    const targetId = idMapping[id];

    const result = value
        .filter((item) => item.id === targetId)
        .map((item) => item.threshold);

    return result;
};

export default LowThreshold;