import { RadioButton, RadioButtons } from '@veneer/core';
import React, { memo, useContext, useEffect, useState } from 'react';
import { ConstraintsEffectEnum } from '../../config/ConstraintsEnums';
import { FeatureEffectivePolicyEnum } from '../../config/FeatureEnums';
import { SubFeatureAccessModeEnum } from '../../config/SubFeatureEnums';
import EditDataContext from '../../context/EditDataContext';
import LocalizeHelper from '../../helper/LocalizeHelper';
import TempDataHelper from '../../helper/TempDataHelper';
import { InputTitle } from '../CommonExtended/InputTitle/InputTitle';
import WarningMessage from '../CommonExtended/WarningMessage/WarningMessage';
import { InputWrap } from './Styles';

const RadioButtonControl = (props) => {
  const { device_settings, featuresId, isChecked, isControlledPolicy } = props;
  const { getRulesEffect } = TempDataHelper();

  const { onUpdateTempData, tempRulesEffect } = useContext(EditDataContext);

  const { getDeviceSettingsLocString, getDeviceConfigurationsLocString } = LocalizeHelper();

  const [inputVal, setInputVal] = useState([device_settings.fleetValue] || ['0']);

  const [effectVal, setEffectVal] = useState();
  const [warningMsg, setWarningMsg] = useState('');
  const [iconType, setIconType] = useState('warning');

  const label = getDeviceSettingsLocString(featuresId, device_settings.label);
  const tooltipMessage = getDeviceSettingsLocString(featuresId, device_settings.info);

  const isDisable =
    effectVal &&
    (effectVal === ConstraintsEffectEnum.ENABLE || effectVal === ConstraintsEffectEnum.SET)
      ? true
      : isChecked && isChecked
      ? isChecked
      : isControlledPolicy === FeatureEffectivePolicyEnum.controlledPolicy &&
        device_settings.accessMode === SubFeatureAccessModeEnum.READWRITE_DISABLE
      ? true
      : false;

  const radioOptions = device_settings.constraints.options.map((option) => {
    return (
      <RadioButton
        label={getDeviceConfigurationsLocString(featuresId, option.value, device_settings.id)}
        value={option.value}
        disabled={isDisable}
      />
    );
  });

  const snmpv1v2RadioOptions = [
    ...radioOptions,
    <RadioButton
      label={getDeviceConfigurationsLocString(featuresId, 'disable', device_settings.id)}
      value="disable"
      disabled={isDisable}
      />
  ];

  const handleOnchange = (e, value) => {
    setInputVal([value]);

    onUpdateTempData({
      settingsName: device_settings.settingsName,
      fleetValue: value,
      attribute: device_settings.attribute,
      error: false,
    });
  };

  useEffect(() => {
    const ruleEffect = getRulesEffect(tempRulesEffect, device_settings.settingsName).effect;
    setEffectVal(ruleEffect);
  }, [tempRulesEffect]);

  useEffect(() => {
    if (inputVal[0] === 'readWrite') {
      setWarningMsg(getDeviceSettingsLocString(featuresId, 'warning_message'));
      setIconType('warning');
    } else if (inputVal[0] === 'disable') {
      setWarningMsg(getDeviceSettingsLocString(featuresId, 'hint_message'));
      setIconType('hint');
    } else {
      setWarningMsg('');
      setIconType('');
    }
  }, [inputVal]);

  return (
    <>
    <InputWrap>
      <InputTitle
        title={label}
        tooltipMessage={tooltipMessage}
        info={device_settings.info}
        disable={isDisable}
      />
      <RadioButtons
        alignment="vertical"
        name="radiobutton"
        defaultValue="0"
        value={inputVal[0]}
        onChange={(e, value) => handleOnchange(e, value)}
      >
        {device_settings.constraints.name === 'snmpV1V2Config.accessOption'
              ? snmpv1v2RadioOptions
              : radioOptions}
      </RadioButtons>
    </InputWrap>
    {!!warningMsg ? <WarningMessage warningMsg={warningMsg} iconType={iconType}/> : null}
    </>
  );
};

export default memo(RadioButtonControl);
