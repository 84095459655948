import { Button, ButtonGroup } from '@veneer/core';
import React, { memo, useContext, useEffect, useState } from 'react';
import { SubfeatureIdEnum } from '../../../config/SubFeatureEnums';
import EditDataContext from '../../../context/EditDataContext';
import LocalizeHelper from '../../../helper/LocalizeHelper';
import CheckBoxControl from './CheckBoxControl';
import OutgoingServersModal from './OutgoingServersModal';
import OutgoingServersTable from './OutgoingServersTable';
import RadioButtonControl from './RadioButtonControl';
import { ButtonSelectedLabel, FlexColumn, FlexRow, StyledButton, TextBlackVariant1, TextBlackVariant2 } from './Styles';
import { InputLabel } from '../../CommonExtended/InputTitle/InputLabel';

enum TABLE_CONSTANTS {
  ID = 'id',
  NO_DATA = '--',
  JOINER = ', ',
}

enum outgoingServerEnum {
  ADDRESS = 'address',
  PORT = 'port',
  SPLIT_EMAIL = 'splitEmail',
  SSL = 'ssl',
  VALIDATE_CERT = 'validateCert',
  AUTH = 'auth',
  EMAIL = 'email',
  FAX = 'fax',
  ALERT = 'alert',
  AUTOSEND = 'autosend',
  CREDENTIAL = 'credential',
  USER = 'user',
  PASSWORD = 'password',
  USAGE = 'usage', // UI only
}

const defaultTableItems = () => ({
  [outgoingServerEnum.EMAIL]: [],
  [outgoingServerEnum.FAX]: [],
  [outgoingServerEnum.ALERT]: [],
  [outgoingServerEnum.AUTOSEND]: [],
});

const defaultUsage = {
  [outgoingServerEnum.EMAIL]: false,
  [outgoingServerEnum.FAX]: false,
  [outgoingServerEnum.ALERT]: false,
  [outgoingServerEnum.AUTOSEND]: false,
};

const defaultData = {
  [outgoingServerEnum.ADDRESS]: '',
  [outgoingServerEnum.PORT]: '',
  [outgoingServerEnum.SPLIT_EMAIL]: '0',
  [outgoingServerEnum.SSL]: false,
  [outgoingServerEnum.VALIDATE_CERT]: false,
  [outgoingServerEnum.AUTH]: false,
  [outgoingServerEnum.CREDENTIAL]: 'signInUser',
  [outgoingServerEnum.USER]: '',
  [outgoingServerEnum.PASSWORD]: '',

  ...defaultUsage,
};

const table_Limit = 25;

const OutgoingServersControl = (props) => {
  const { onUpdateTempData } = useContext(EditDataContext);

  const OutgoingServersControlEmail = props.subfeaturesdata.find(
    (_data) => _data.id === SubfeatureIdEnum.Outgoing_Server_List_Email,
  );

  const OutgoingServersPort = props.subfeaturesdata.find(
    (_data) => _data.id === 'outgoing-email-server-port',
  );

  defaultData[outgoingServerEnum.PORT] = OutgoingServersPort.fleetValue || '';

  const OutgoingServersControlOverWrite = props.subfeaturesdata.find(
    (_data) => _data.id === SubfeatureIdEnum.Outgoing_Server_List_OverwriteOption,
  );

  const OverWriteExistingName = props.subfeaturesdata.find(
    (_data) => _data.id === SubfeatureIdEnum.Outgoing_Server_List_OverWriteExistingName,
  );

  const [value, setValue] = useState([]);
  const [tableData, setTableData] = useState(defaultTableItems());
  const [uniqueCount, setUniqueCount] = useState(0);
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const { getDeviceSettingsLocString } = LocalizeHelper();

  const sendToEmail = getDeviceSettingsLocString(props.id, 'send_to_email');
  const internetFax = getDeviceSettingsLocString(props.id, 'internet_fax');
  const alert = getDeviceSettingsLocString(props.id, 'email_alerts');
  const autoSend = getDeviceSettingsLocString(props.id, 'auto_send');
  const serverList_label = getDeviceSettingsLocString(props.id, 'serverList_label');
  const usageEmail = getDeviceSettingsLocString(props.id, 'usage_email');
  const usageFax = getDeviceSettingsLocString(props.id, 'usage_fax');
  const usageAlert = getDeviceSettingsLocString(props.id, 'usage_alert');
  const usageAutosend = getDeviceSettingsLocString(props.id, 'usage_autosend');
  const servers_limit = getDeviceSettingsLocString(props.id, 'servers-limit', {
    count: selectedCount,
  });
  const usageItems = [
    {
      id: outgoingServerEnum.EMAIL,
      label: sendToEmail,
      tag: usageEmail,
    },
    {
      id: outgoingServerEnum.FAX,
      label: internetFax,
      tag: usageFax,
    },
    {
      id: outgoingServerEnum.ALERT,
      label: alert,
      tag: usageAlert,
    },
    {
      id: outgoingServerEnum.AUTOSEND,
      label: autoSend,
      tag: usageAutosend,
    },
  ];

  const setData = (newTableData) => {
    setTableData(newTableData);
    const newItem = (item, id) => ({
      ...item,
      ...defaultUsage,
      [id]: true,
    });
    const newValue = newTableData[usageItems[0].id].map((x) =>
      newItem(x.rowConfig.item, usageItems[0].id),
    );
    usageItems.slice(1).forEach(({ id }) => {
      newTableData[id].forEach((x) => {
        newValue.push(newItem(x.rowConfig.item, id));
      });
    });
  };

  const onChangeTable = (tableId, data, select) => {
    if (!select) {
      setData({ ...tableData, [tableId]: data });
      return; // no need to update other tables
    }
    // update all tables
    const newTableData = { ...defaultTableItems(), [tableId]: data };
    usageItems
      .filter(({ id }) => tableId !== id)
      .forEach(({ id }) => {
        newTableData[id] = tableData[id].map((x) => {
          const found = data.find(
            (y) => y[outgoingServerEnum.ADDRESS] === x[outgoingServerEnum.ADDRESS],
          );
          return found ? { ...found, [TABLE_CONSTANTS.ID]: x.id } : x;
        });
      });
    setTableData(newTableData);
  };

  const onAdd = (edit = false) => {
    let item = null;
    if (edit) {
      for (const { id } of usageItems) {
        const found = tableData[id].find((x) => x.rowConfig.selected);
        if (found) {
          item = found.rowConfig.item;
          break;
        }
      }
    }

    setSelectedItem(item);
    setShowModal(true);
  };

  const onRemove = () =>
    setData(
      usageItems.reduce((acc, { id }) => {
        acc[id] = tableData[id].filter((x) => !x.rowConfig.selected);
        return acc;
      }, {}),
    );

  const tableItem = (item, i) => {
    const usage = usageItems
      .filter(({ id }) => item[id])
      .map(({ tag }) => tag)
      .join(', ');
    const selected = usageItems.some(
      ({ id }) =>
        tableData[id].find(
          (x) => x[outgoingServerEnum.ADDRESS] === item[outgoingServerEnum.ADDRESS],
        )?.rowConfig.selected,
    );
    return {
      [TABLE_CONSTANTS.ID]: i,
      [outgoingServerEnum.ADDRESS]: item[outgoingServerEnum.ADDRESS],
      [outgoingServerEnum.PORT]: item[outgoingServerEnum.PORT],
      [outgoingServerEnum.USAGE]: usage,
      rowConfig: { selected, item },
    };
  };

  const onChange = (v) => {
    const newTableData = defaultTableItems();
    if (selectedItem) {
      const address = selectedItem[outgoingServerEnum.ADDRESS];
      usageItems.forEach(({ id }) => {
        const arr = tableData[id];
        if (v[id]) {
          const index = arr.findIndex((x) => x[outgoingServerEnum.ADDRESS] === address);
          newTableData[id] =
            index >= 0
              ? [...arr.slice(0, index), tableItem(v, index), ...arr.slice(index + 1)]
              : [...arr, tableItem(v, arr.length)];
        } else {
          newTableData[id] = arr.filter((x) => x[outgoingServerEnum.ADDRESS] !== address);
        }
      });
    } else {
      usageItems.forEach(({ id }) => {
        const arr = tableData[id];
        newTableData[id] = v[id] ? [...arr, tableItem(v, arr.length)] : [...arr];
      });
    }

    setData(newTableData);
  };

  useEffect(() => {
    let selected = 0;
    const uniqueItems = usageItems.reduce((acc, { id }) => {
      tableData[id].forEach((x) => {
        if (!acc.includes(x[outgoingServerEnum.ADDRESS])) {
          acc.push(x[outgoingServerEnum.ADDRESS]);
          if (x.rowConfig.selected) {
            selected++;
          }
        }
      });
      return acc;
    }, []);
    setUniqueCount(uniqueItems.length);
    setSelectedCount(selected);

    const emailRowConfigItems = tableData.email.map((item) => item.rowConfig.item);
    const faxRowConfigItems = tableData.fax.map((item) => item.rowConfig.item);
    const alertRowConfigItems = tableData.alert.map((item) => item.rowConfig.item);
    const autosendRowConfigItems = tableData.autosend.map((item) => item.rowConfig.item);

    const tableItems = emailRowConfigItems.concat(faxRowConfigItems, alertRowConfigItems, autosendRowConfigItems);

    const uniqueTableItems = tableItems.filter(
      (item, index, self) => index === self.map((item) => item.address).indexOf(item.address),
    );

    setValue(uniqueTableItems);
  }, [tableData]);

  useEffect(() => {
    onUpdateTempData({
      settingsName: OutgoingServersControlEmail?.settingsName,
      fleetValue: value,
      attribute: OutgoingServersControlEmail?.attribute,
      error: false,
    });
  }, [value]);

  useEffect(() => {
    const transformData = (data) => {
      return data.map((item) => ({
        address: item.serverAddress,
        port: item.serverPort,
        splitEmail: item.fileSize ? item.fileSize : '0', // Assuming fileSize is equivalent to splitEmail
        ssl: item.useSsl ? item.useSsl === 'true' : false,
        // validateCert: item.validateServerCertificate
        //   ? item.validateServerCertificate === 'true'
        //   : false,
        auth: item.serverRequireAuthentication
          ? item.serverRequireAuthentication === 'true'
          : false,
        // credential: item.credential.credentialType, // Assuming credentialType is equivalent to credential
        email: item.serverUsage.email ? item.serverUsage.email === 'true' : false,
        fax: item.serverUsage.fax ? item.serverUsage.fax === 'true' : false, // If fax is not present, it will be undefined
        alert: item.serverUsage.alert ? item.serverUsage.alert === 'true' : false,
        autosend: item.serverUsage.autosend ? item.serverUsage.autosend === 'true' : false, // autosend is not present in the original data, so setting it to false
        ...((item.serverRequireAuthentication || item.serverRequireAuthentication === 'true') && {
          credential: item.credential.credentialType,
        }),

        ...((item.useSsl || item.useSsl === 'true') && {
          validateCert: item.validateServerCertificate
            ? item.validateServerCertificate === 'true'
            : false,
        }),
      }));
    };

    const answerData = Array.isArray(OutgoingServersControlEmail?.fleetValue)
      ? transformData(OutgoingServersControlEmail?.fleetValue)
      : [];

    setValue(answerData);

    const transformData1 = (data) => {
      let id = 0;
      const result = { email: [], fax: [],  alert: [], autosend: [] };

      if (Array.isArray(data)) {
        data.forEach((item) => {
          const newItem = {
            id: id++,
            address: item.serverAddress,
            port: item.serverPort,
            usage: '',
            rowConfig: {
              selected: false,
              item: {
                address: item.serverAddress,
                port: item.serverPort,
                splitEmail: item.fileSize ? item.fileSize : '0',
                ssl: item.useSsl ? (item.useSsl === 'true' ? true : false) : false,
                auth: item.serverRequireAuthentication
                  ? item.serverRequireAuthentication === 'true'
                    ? true
                    : false
                  : false,
                email: item.serverUsage.email ? item.serverUsage.email === 'true' : false,
                fax: item.serverUsage.fax ? item.serverUsage.fax === 'true' : false,
                alert: item.serverUsage.alert ? item.serverUsage.alert === 'true' : false,
                autosend: item.serverUsage.autosend ? item.serverUsage.autosend === 'true' : false,
                ...((item.serverRequireAuthentication ||
                  item.serverRequireAuthentication === 'true') && {
                  credential: item.credential.credentialType,
                }),

                ...((item.useSsl || item.useSsl === 'true') && {
                  validateCert: item.validateServerCertificate
                    ? item.validateServerCertificate === 'true'
                    : false,
                }),
              },
            },
          };

          if (newItem.rowConfig.item.email) {
            newItem.usage += 'Email, ';
            result.email.push(newItem);
          }
          if (newItem.rowConfig.item.fax) {
            newItem.usage += 'Fax, ';
            result.fax.push(newItem);
          }
          if (newItem.rowConfig.item.alert) {
            newItem.usage += 'Email Alerts, ';
            result.alert.push(newItem);
          }
          if (newItem.rowConfig.item.autosend) {
            newItem.usage += 'AutoSend, ';
            result.autosend.push(newItem);
          }

          newItem.usage = newItem.usage.slice(0, -2); // Remove trailing comma and space
        });
      }

      return result;
    };

    const answerData1 = transformData1(OutgoingServersControlEmail?.fleetValue);
    setTableData(answerData1);
  }, []);

  return (
    <>
      <FlexColumn>
        <RadioButtonControl
          {...props}
          id={props.id}
          subfeaturesdata={[OutgoingServersControlOverWrite]}
          isChecked={props.isChecked}
        />
        <CheckBoxControl
          {...props}
          id={props.id}
          subfeaturesdata={[OverWriteExistingName]}
          isChecked={props.isChecked}
        />
        <TextBlackVariant2 padding={'16px'} className={'bold'}>
          {serverList_label}
        </TextBlackVariant2>
        <FlexRow className={'alignCenter'}>
          <ButtonGroup>
            <StyledButton
              isWex={props.isWex}
              appearance={'secondary'}
              onClick={() => {
                if (value.length <= table_Limit) {
                  onAdd();
                }
              }}
              id={'addOutgoingServer'}
              disabled={props.isChecked || value.length >= table_Limit}
            >
              Add
            </StyledButton>
            <StyledButton
              isWex={props.isWex}
              appearance={'secondary'}
              onClick={() => onAdd(true)}
              disabled={props.isChecked || selectedCount !== 1}
              id={'editOutgoingServer'}
            >
              Edit
            </StyledButton>
            <StyledButton
              isWex={props.isWex}
              appearance={'secondary'}
              onClick={onRemove}
              disabled={props.isChecked || !selectedCount}
              id={'removeOutgoingServer'}
            >
              Remove
            </StyledButton>
            <ButtonSelectedLabel>
              <InputLabel disabled={props.isChecked} title={servers_limit} />
            </ButtonSelectedLabel>
          </ButtonGroup>
          <TextBlackVariant1 className={'marginLeft12'}></TextBlackVariant1>
        </FlexRow>
        {usageItems.map(({ id }) => (
          <OutgoingServersTable
            key={id}
            usage={id}
            tableData={tableData[id]}
            setTableData={(data, select) => onChangeTable(id, data, select)}
            id={props.id}
            isChecked={props.isChecked}
          />
        ))}
      </FlexColumn>
      {showModal && (
        <OutgoingServersModal
          value={selectedItem || defaultData}
          edit={!!selectedItem}
          onChange={(v) => onChange(v)}
          onClose={() => setShowModal(false)}
          tableValue={value}
          {...props}
        />
      )}
    </>
  );
};

export default memo(OutgoingServersControl);
