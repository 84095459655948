import { useDispatch } from 'react-redux';
import CategoryTreeHelper from '../helper/CategoryTreeHelper';
import { setFeaturesData, setRulesData, setSettingsData } from '../store/deviceConfig/action';
import CategoryControl from './CategoryControl';
import ConstraintsRuleParser from './ConstraintsRuleParser';
import logger from '../../src/utils/logger';

const BatchModeDeviceConfigParser = () => {
  const { getAsyncJsonData } = CategoryControl();
  const dispatch = useDispatch();
  const { validateIfRuleExists, tempConditionCheck } = ConstraintsRuleParser();
  const { getPolicyAttributeDescription } = CategoryTreeHelper();

  const filterPolicyData = (policyData, combineDataId) => {
    const filteredPolicy = policyData.length
      ? policyData.filter((policy) => policy.attributeName == combineDataId.substring(3))
      : [];
    return {
      controlledPolicy: filteredPolicy.length ? filteredPolicy[0].controlledPolicy : [],
      effectivePolicy: filteredPolicy.length ? filteredPolicy[0].policyType : null,
    };
  };

  const mapCombineDataFeatures = (combineDatas, policyData, key) => {
    return combineDatas
      ?.map((_combineData) => {
        const categoryLevel = getPolicyAttributeDescription(_combineData.id);
        const { controlledPolicy, effectivePolicy } = filterPolicyData(policyData, _combineData.id);
        return { ..._combineData, categoryName: key, categoryLevel, controlledPolicy, effectivePolicy };
      })
      .filter(
        (_resultCombineData) =>
          (_resultCombineData.configurable === undefined || _resultCombineData.configurable === true) &&
          (_resultCombineData.visible === undefined || _resultCombineData.visible === true),
      );
  };

  const mapCombineDataSettings = (combineDatas) => {
    return combineDatas
      ?.map((_combineData) => _combineData.subfeatures)
      .flat(1);
  };

  const mapFinalSettings = (dataModelSettings, constraintsResources, deviceModelNumber) => {
    return dataModelSettings
      ?.map((_dataModelSetting) => {
        const res = constraintsResources?.find(
          (_constraintsResource) => _constraintsResource.resource === _dataModelSetting.resource,
        );
        if (res) {
          const constraints = res.settings?.find(
            (_constraintsSetting) => _constraintsSetting.name === _dataModelSetting.settingsName,
          );

          const isExcludeDeviceModelNumber = constraints?.excludeDeviceModelNumberList?.filter(
            (e) => deviceModelNumber.indexOf(e) !== -1,
          );

          if (constraints && isExcludeDeviceModelNumber?.length !== deviceModelNumber.length) {
            return {
              ..._dataModelSetting,
              notSupportedFeatures: res.notSupportedFeatures,
              constraints,
              fleetValue: constraints.defaultValue ? constraints.defaultValue : '',
            };
          }
        }
      })
      .filter((_resultSetting) => _resultSetting !== undefined);
  };

  const mapResultFeatures = (dataModelFeatures, finalSettings) => {
    return dataModelFeatures
      ?.map((_dataModelFeatue) => {
        return {
          ..._dataModelFeatue,
          subfeatures: _dataModelFeatue.subfeatures
            ?.map((_subfeature) => {
              const result = finalSettings?.find(
                (_finalSetting) =>
                  _finalSetting.id === _subfeature.id &&
                  (_subfeature.visible == undefined || _subfeature.visible),
              );
              return result;
            })
            .filter((_resultSubfeature) => _resultSubfeature !== undefined),
        };
      })
      .filter((_resultDataModelFeatue) => _resultDataModelFeatue.subfeatures.length);
  };

  const addNotSupportedDevices = (resultFeatures) => {
    return resultFeatures?.map((_resultFeature) => {
      const filteredSettings = _resultFeature.subfeatures.filter(
        (_subfeature) => _subfeature.notSupportedFeatures && _subfeature.notSupportedFeatures,
      );

      const filterNotSupportedFeatures = filteredSettings
        ?.map((_filteredSetting) => _filteredSetting.notSupportedFeatures)
        .flat(1);

      const notSuppotedFeaturesResult = filterNotSupportedFeatures.filter((c, index) => {
        return filterNotSupportedFeatures.indexOf(c) === index;
      });

      if (notSuppotedFeaturesResult.length) {
        if (_resultFeature.id === notSuppotedFeaturesResult[0].featureId) {
          _resultFeature.notSupportedDevices = notSuppotedFeaturesResult[0].deviceName;
        }
      }

      return {
        ..._resultFeature,
        subfeatures: _resultFeature.subfeatures?.map((_subfeature) => {
          delete _subfeature.notSupportedDevices;
          return { ..._subfeature };
        }),
      };
    });
  };

  const getBatchModeModalData = (constraintsResources, deviceModelNumber, policyData) => {
    return new Promise((resolve, reject) => {
      getAsyncJsonData()
        .then((combinedSettings: any) => {
          const dataModelFeatures = [];
          const dataModelSettings = [];

          Object.keys(combinedSettings).forEach((key) => {
            const combineDatas = combinedSettings[key];
            dataModelFeatures.push(...mapCombineDataFeatures(combineDatas, policyData, key));
            dataModelSettings.push(...mapCombineDataSettings(combineDatas));
          });

          const finalSettings = mapFinalSettings(dataModelSettings, constraintsResources, deviceModelNumber);
          const resultFeatures = mapResultFeatures(dataModelFeatures, finalSettings);
          const finalFeatures = addNotSupportedDevices(resultFeatures);

          const rulesExistsData = finalSettings
            ?.map((_finalSetting) => validateIfRuleExists(_finalSetting.constraints, _finalSetting.settingsName))
            .flat(1);

          const filteredRulesExistsData = rulesExistsData.filter((_rulesExistsData) => _rulesExistsData.dependsOn.length);

          dispatch(setFeaturesData(finalFeatures));
          dispatch(setSettingsData(finalSettings));
          dispatch(setRulesData(filteredRulesExistsData));

          if (filteredRulesExistsData.length) {
            finalSettings?.map((_setting) => tempConditionCheck(filteredRulesExistsData, _setting));
          }

          resolve('Success');
        })
        .catch((err) => {
          logger.error('BatchModeDeviceConfigParser_TemplateControlsParser :: getMergedTabControls rejecting with error', err);
          reject(err);
        });
    });
  };

  return {
    getBatchModeModalData,
  };
};

export default BatchModeDeviceConfigParser;