import React, { useLayoutEffect, useRef, useState } from 'react';
import { SubfeatureIdEnum } from '../../../../config/SubFeatureEnums';
import LocalizeHelper from '../../../../helper/LocalizeHelper';
import * as Style from './Styles';

const ClampedDiv = ({ children, id, subId }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const { getCommonStrings, getDeviceSettingsLocString } = LocalizeHelper();
  const moreText = getCommonStrings('more');

  // This is where I'd do the line number calculation, but it's just
  // using a placeholder instead.
  const [showLink, setShowLink] = useState(false);

  useLayoutEffect(() => {
    if (ref.current && ref.current.clientHeight < ref.current.scrollHeight) {
      setShowLink(true);
    }
  }, [ref]);

  let textClass = 'text';
  if (open) {
    textClass += ' open';
  }

  const customValue = () => {
    let value;
    value = children !== '' && children !== 'noSuchInstance' ? children : '--';
    return value;
  };

  return (
    <Style.SubFeatureTextContainer>
      <Style.SubFeatureText type={textClass} ref={ref}>
        {customValue()}
      </Style.SubFeatureText>
      {showLink && !open && (
        <Style.SubFeatureTextButton onClick={() => setOpen(true)}>
          {moreText}
        </Style.SubFeatureTextButton>
      )}
    </Style.SubFeatureTextContainer>
  );
};

export default ClampedDiv;
