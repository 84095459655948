import React, { Fragment } from 'react';
import { FeatureSection } from './Section/FeatureSection';

export const FeatureContainer = (props) => {
  const { dataModelFeatures,entitlements, isWex} = props;

  return (
    <Fragment>
      {dataModelFeatures.map((featuresData) => {
        return (
          <FeatureSection
            featuresData={featuresData}
            handleOnEditClick={props.handleOnEditClick}
            data-testid={'id-single-mode-feature-section'}
            entitlements = {entitlements}
            isWex = {isWex}
          />
        );
      })}
    </Fragment>
  );
};
