import { Card } from '@veneer/core';
import { useI18n } from '@jarvis/react-portal-addons';
import React, { useContext } from 'react';
import { shellProps } from '../../../shellProps';
import * as Style from './Styles';

export const ControlledPolicy = (props) => {
  const { showMultipleConfigUi, selectedDeviceIdList} = useContext(shellProps);

  const { t } = useI18n();
  const he = require('he');
  const controlledPolicy = [...props.policyName];
  const policyName = controlledPolicy
    .map((x) => {
      if (x === 'deviceSpecificPolicy') {
        return t('ecp-device-details-configuration.commonstrings.deviceSpecificPolicy');
      }
      return x;
    })
    .join(' , ')
    .replace(/, ([^,]*)$/, ' and $1');

  const content = () => {
    return (
      <Style.ControlledPolicyCard>
        <Style.IconWarningAltWrap filled={true} size={24} />
        <Style.ControlledPolicyCardContent>
          <Style.ControlledPolicyCardTitle>
            {t('ecp-device-details-configuration.commonstrings.controlledSettingsTitle')}
          </Style.ControlledPolicyCardTitle>
          <Style.ControlledPolicyCardDesc>
            {he.decode(t(
              showMultipleConfigUi && selectedDeviceIdList?.length > 1
                ? 'ecp-device-details-configuration.commonstrings.controlledSettingsSubtitleBatchMode'
                : 'ecp-device-details-configuration.commonstrings.controlledSettingsSubtitle',
              {
                policy: policyName,
              },
            ))}
          </Style.ControlledPolicyCardDesc>
        </Style.ControlledPolicyCardContent>
      </Style.ControlledPolicyCard>
    );
  };
  return (
    <div>
      <Card
        content={content() as any}
        customStyle={{
          borderRadius: '8px',
          marginBottom: '16px',
        }}
      />
    </div>
  );
};
