import { Password } from '@veneer/core';
import React, { Fragment, memo, useContext, useEffect, useState } from 'react';
import { ConstraintsEffectEnum } from '../../../../config/ConstraintsEnums';
import { FeatureEffectivePolicyEnum } from '../../../../config/FeatureEnums';
import { SubFeatureAccessModeEnum } from '../../../../config/SubFeatureEnums';
import { CustomPasswordValidator } from '../../../../constraintsValidators/CustomPasswordValidator';
import EditDataContext from '../../../../context/EditDataContext';
import LocalizeHelper from '../../../../helper/LocalizeHelper';
import TempDataHelper from '../../../../helper/TempDataHelper';
import { InputTitle } from '../../../CommonExtended/InputTitle/InputTitle';
import GenerateButtonControl from './GenerateButtonControl';
import { InputWrap } from './Styles';
const PasswordControl = (props) => {
  const { device_settings, featuresId, isChecked, isControlledPolicy,isWex } = props;
  const { onUpdateTempData, tempRulesEffect, customErrorData, editViewData } =
    useContext(EditDataContext);

  const regexData = device_settings.constraints.splitRegex;
  const constraintsData = device_settings.constraints;
  const constraintDataType = device_settings.constraints.type;
  const deviceSettingsAttr = device_settings.attribute;

  const {
    validatePassword,
    validateIntPassword,
    validateConfirmPassword,
    requirementsItems,
    errorState,
    confPassErrorState,
    hide,
    setHide,
    passwordError,
    confirmPasswordError,
  } = CustomPasswordValidator(regexData, constraintsData, device_settings.id, editViewData);

  const { getRulesEffect } = TempDataHelper();
  // Localization Helper
  const { getDeviceSettingsLocString, getCustomizableComponentStrings } = LocalizeHelper();
  const [inputVal, setInputVal] = useState('');
  const [confirmPwdInputVal, confirmPwdsetInputVal] = useState('');
  const [effectVal, setEffectVal] = useState();
  const [passwordComplexityValue, setPasswordComplexityValue] = useState(true);
  const [minPasswordLengthValue, setMinPasswordLengthValue] = useState(true);

  // Strings
  const label = getDeviceSettingsLocString(featuresId, device_settings.label);
  const confirmPasswordLabel = getDeviceSettingsLocString(
    featuresId,
    device_settings.confirmPasswordLabel,
  );
  const tooltipMessage = getDeviceSettingsLocString(featuresId, device_settings.info);
  const confirmPasswordErrMsg = getDeviceSettingsLocString(
    featuresId,
    `${device_settings.id}-confirmpassword_error`,
  );
  const isDisable =
    effectVal &&
    (effectVal === ConstraintsEffectEnum.ENABLE || effectVal === ConstraintsEffectEnum.SET)
      ? true
      : isChecked && isChecked
      ? isChecked
      : isControlledPolicy === FeatureEffectivePolicyEnum.controlledPolicy &&
        device_settings.accessMode === SubFeatureAccessModeEnum.READWRITE_DISABLE
      ? true
      : false;

  useEffect(() => {
    if (regexData || constraintsData.minimum || constraintsData.maximum) {
      validateConfirmPassword(inputVal, confirmPwdInputVal, confirmPasswordErrMsg);
    }
  }, [confirmPwdInputVal]);
  const handleOnchange = (value) => {
    const isInteger = /^[0-9]+$/;
    let inputErrorData;
    let inputValue;
    if (regexData || constraintsData.minimum || constraintsData.maximum) {
      if (constraintDataType == 'integer') {
        if (value === '' || isInteger.test(value)) {
          validateIntPassword(value);
          validateConfirmPassword(value, confirmPwdInputVal, confirmPasswordErrMsg);
          setInputVal(value);
          inputValue = value;
          inputErrorData =
            validateIntPassword(value) ||
            validateConfirmPassword(value, confirmPwdInputVal, confirmPasswordErrMsg);
        } else {
          inputValue = inputVal;
        }
      } else {
        validatePassword(value);
        validateConfirmPassword(value, confirmPwdInputVal, confirmPasswordErrMsg);
        setInputVal(value);
        inputValue = value;
        inputErrorData =
          validatePassword(value) ||
          validateConfirmPassword(value, confirmPwdInputVal, confirmPasswordErrMsg);
      }
    }
    onUpdateTempData({
      settingsName: device_settings.settingsName,
      fleetValue: inputValue,
      confirmPasswordValue: confirmPwdInputVal,
      attribute: device_settings.attribute,
      error: inputErrorData,
    });
  };
  const confirmPassword = (value) => {
    const isInteger = /^[0-9]+$/;
    let inputErrorData;
    let inputValue;
    if (regexData || constraintsData.minimum || constraintsData.maximum) {
      if (constraintDataType == 'integer') {
        if (value === '' || isInteger.test(value)) {
          validateConfirmPassword(inputVal, value, confirmPasswordErrMsg);
          confirmPwdsetInputVal(value);
          inputValue = value;
          inputErrorData =
            validatePassword(inputVal) ||
            validateConfirmPassword(inputVal, value, confirmPasswordErrMsg);
        } else {
          inputValue = confirmPwdInputVal;
        }
      } else {
        validateConfirmPassword(inputVal, value, confirmPasswordErrMsg);
        confirmPwdsetInputVal(value);
        inputValue = value;
        inputErrorData =
          validatePassword(inputVal) ||
          validateConfirmPassword(inputVal, value, confirmPasswordErrMsg);
      }
    }
    onUpdateTempData({
      settingsName: device_settings.settingsName,
      fleetValue: inputVal,
      confirmPasswordValue: inputValue,
      attribute: device_settings.attribute,
      error: inputErrorData,
    });
  };
  const handleOnBlur = (event) => {
    setHide(true);
    if (regexData || constraintsData.minimum || constraintsData.maximum) {
      if (constraintDataType == 'string') {
        validatePassword(event.target.value);
        validateConfirmPassword(event.target.value, confirmPwdInputVal, confirmPasswordErrMsg);
      } else {
        validateIntPassword(event.target.value);
        validateConfirmPassword(event.target.value, confirmPwdInputVal, confirmPasswordErrMsg);
      }
    }
  };
  const handleOnBlur2 = (event) => {
    setHide(true);
    if (regexData || constraintsData.minimum || constraintsData.maximum) {
      validateConfirmPassword(inputVal, event.target.value, confirmPasswordErrMsg);
    }
  };
  const generatePassword = (value) => {
    setInputVal(value);
    confirmPwdsetInputVal(value);
    let inputErrorData;
    if (regexData || constraintsData.minimum || constraintsData.maximum) {
      validatePassword(value);
      validateConfirmPassword(value, value, confirmPasswordErrMsg);
      inputErrorData =
        validatePassword(value) || validateConfirmPassword(value, value, confirmPasswordErrMsg);
    }
    onUpdateTempData({
      settingsName: device_settings.settingsName,
      fleetValue: value,
      confirmPasswordValue: value,
      attribute: device_settings.attribute,
      error: inputErrorData,
    });
  };
  useEffect(() => {
    const ruleEffect = getRulesEffect(tempRulesEffect, device_settings.settingsName).effect;
    setEffectVal(ruleEffect);
  }, [tempRulesEffect]);

  useEffect(() => {
    if (device_settings.id === 'ews-admin-Proposed-password') {
      onUpdateTempData({
        settingsName: device_settings.settingsName,
        fleetValue: inputVal,
        confirmPasswordValue: confirmPwdInputVal,
        attribute: device_settings.attribute,
        error: errorState,
      });
    }
  }, [errorState]);

  const handleOnFocus = (event) => {
    if (regexData || constraintsData.minimum || constraintsData.maximum) {
      if (constraintDataType == 'string') {
        validatePassword(event.target.value);
        validateConfirmPassword(event.target.value, confirmPwdInputVal, confirmPasswordErrMsg);
      } else {
        validateIntPassword(event.target.value);
        validateConfirmPassword(event.target.value, confirmPwdInputVal, confirmPasswordErrMsg);
      }
    }
  };

  useEffect(() => {
    if (device_settings.id === 'ews-admin-Proposed-password') {
      if (!requirementsItems.length) {
        setHide(true);
      }
    }
  }, [requirementsItems]);

  useEffect(() => {
    const passwordComplexity =
      editViewData.length &&
      editViewData.filter((_data) => _data.name === 'ews-password.complexity')[0]?.value;

    const minPasswordLength =
      editViewData.length &&
      editViewData.filter((_data) => _data.name === 'ews-password.min-length')[0]?.value;

    setPasswordComplexityValue(passwordComplexity);
    setMinPasswordLengthValue(minPasswordLength);
  }, [editViewData]);

  useEffect(() => {
    if (inputVal !== '') {
      validatePassword(inputVal);
      validateConfirmPassword(inputVal, confirmPwdInputVal, confirmPasswordErrMsg);
    }
  }, [passwordComplexityValue, minPasswordLengthValue]);

  return (
    <Fragment>
      <GenerateButtonControl
        titleGenerate={getCustomizableComponentStrings('generatePassword')}
        passwordGenerate={generatePassword}
        copyPassword={inputVal}
        isDisable={isDisable}
        isWex ={isWex}
      />
      <InputWrap>
        <InputTitle
          title={label}
          tooltipMessage={tooltipMessage}
          info={device_settings.info}
          disable={isDisable}
        />
        <Password
          className={'password'}
          id={device_settings.attribute}
          disabled={isDisable}
          value={inputVal}
          error={
            isDisable
              ? false
              : errorState
              ? errorState
              : customErrorData.name === deviceSettingsAttr
              ? customErrorData.error
              : false
          }
          onChange={(value) => handleOnchange(value)}
          requirements={requirementsItems}
          hideRequirements={hide}
          onFocus={
            regexData || constraintsData.minimum || constraintsData.maximum
              ? (e) => {
                  setHide(false);
                  handleOnFocus(e);
                }
              : (e) => {
                  setHide(true);
                  handleOnFocus(e);
                }
          }
          onBlur={handleOnBlur}
          i18n={errorState ? passwordError : customErrorData.errorMsg}
        />
      </InputWrap>
      <InputWrap>
        <InputTitle title={confirmPasswordLabel} disable={isDisable} />
        <Password
          className={'password'}
          id={device_settings.attribute}
          value={confirmPwdInputVal}
          disabled={isDisable}
          onChange={(value) => {
            confirmPassword(value);
          }}
          error={isDisable ? false : confPassErrorState}
          requirements={requirementsItems}
          hideRequirements={true}
          onBlur={handleOnBlur2}
          i18n={confirmPasswordError}
        />
      </InputWrap>
    </Fragment>
  );
};
export default memo(PasswordControl);
