import LocalizeHelper from '../../helper/LocalizeHelper';
export const AccessControlPermissionSetsHelper = (value, deviceConstraints, id) => {
  const { getDeviceCommonLocString } = LocalizeHelper();
  const constraintRolesMap = deviceConstraints.options.reduce((acc, option) => {
    acc[option.value] = option.id;
    return acc;
  }, {});
  const matchingRoles = value.filter((data) => constraintRolesMap.hasOwnProperty(data.role));
  const matchingRolesWithId = matchingRoles.map((data) => ({
    permission: data.id,
    role: data.role,
    type: 'Device Default',
  }));
  const nonMatchingRoles = value.filter((data) => !constraintRolesMap.hasOwnProperty(data.role));
  const nonMatchingRolesWithId = nonMatchingRoles.map((data) => ({
    permission: data.id,
    role: data.role,
    type: 'custom',
  }));
  let combinedRolesWithId = [...matchingRolesWithId, ...nonMatchingRolesWithId];
  combinedRolesWithId = combinedRolesWithId.map((data) => ({
    ...data,
    permission: getDeviceCommonLocString( id, constraintRolesMap[data.role]) || data.id,
  }));
  return combinedRolesWithId;
};