import React, { Fragment } from 'react';
import {
  SubFeatureControlTypeEnum,
  SubFeatureFleetDataStatus,
} from '../../../../config/SubFeatureEnums';
import LocalizeHelper from '../../../../helper/LocalizeHelper';
import ClampedDiv from './ClampedDiv';
import * as Style from './Styles';
import TableTemplate from './Table/TableTemplate';
import  IPConfigPrecedenceCard  from '../../SubFeature/CustomizableSection/IPConfigPrecedence/IPConfigPrecedenceCard';
import  CartridgeThreshold  from '../../SubFeature/CustomizableSection/LowThreshold/LowThreshold';

export const SectionField = (props) => {
  const { featureId, value, result, subFeatureId , wex ,deviceConstraints } = props;
  const { getFleetValueStrings, getDeviceConfigurationsLocString, getCommonStrings } =
    LocalizeHelper();
  const noDataAvailable = getCommonStrings('noDataAvailable');

  const getFleetDataValue = (id, subId, value, result) => {
    if (value != null && value != 'undefined') {
      if (result == SubFeatureFleetDataStatus.PENDING) {
        return noDataAvailable;
      } else if (result == SubFeatureFleetDataStatus.FAILURE) {
        return noDataAvailable;
      } else {
        return <ClampedDiv children={value} id={id} subId={subId} />;
      }
    } else {
      return noDataAvailable;
    }
  };

  const getFleetDataOptions = (id, value, result, subId) => {
    if (value != null && value != 'undefined') {
      const valueToString = value?.toString();
      if (result == SubFeatureFleetDataStatus.PENDING) {
        return noDataAvailable;
      } else if (result == SubFeatureFleetDataStatus.FAILURE) {
        return noDataAvailable;
      } else if (valueToString) {
        return valueToString !=='noSuchInstance' ? getDeviceConfigurationsLocString(id, valueToString, subId) : '--';
      } else {
        return noDataAvailable;
      }
    } else {
      return noDataAvailable;
    }
  };

  const getFleetDataDefaultValue = (value, result, key) => {
    if (value != null && value != 'undefined') {
      if (result == SubFeatureFleetDataStatus.PENDING) {
        return noDataAvailable;
      } else if (result == SubFeatureFleetDataStatus.FAILURE) {
        return noDataAvailable;
      } else {
        return getFleetValueStrings(key, value);
      }
    } else {
      return noDataAvailable;
    }
  };

  const getFleetDataTable = (id, value, result, subFeatureId , deviceConstraints ) => {
    if (value != null && value != 'undefined') {
      if (result == SubFeatureFleetDataStatus.PENDING) {
        return noDataAvailable;
      } else if (result == SubFeatureFleetDataStatus.FAILURE) {
        return noDataAvailable;
      } else {
        return <TableTemplate id={id} subFeatureId={subFeatureId} value={value} wex = {wex}  deviceConstraints = {deviceConstraints}  />;
      }
    } else {
      return noDataAvailable;
    }
  };

  const getFleetDataIPconfigPrecedence = (id, value, result, subFeatureId) => {
    if (value != null && value != 'undefined') {
      if (result == SubFeatureFleetDataStatus.PENDING) {
        return noDataAvailable;
      } else if (result == SubFeatureFleetDataStatus.FAILURE) {
        return noDataAvailable;
      } else {
        return <IPConfigPrecedenceCard id={id}  value={value} />;
      }
    } else {
      return noDataAvailable;
    }
  };

  const getDeviceCacheCartridgeThreshold = (id, value) => {
    if (value != null && value != 'undefined') {
      return CartridgeThreshold(id, value);
    } else {
      return noDataAvailable;
    }
  };

  let component;
  switch (props.type) {
    case SubFeatureControlTypeEnum.Textbox:
      component = getFleetDataValue(featureId, subFeatureId, value, result);
      break;
    case SubFeatureControlTypeEnum.Password:
      component = getFleetDataDefaultValue(value, result, SubFeatureControlTypeEnum.Password);
      break;
    case SubFeatureControlTypeEnum.Dropbox:
    case SubFeatureControlTypeEnum.Radio:
    case SubFeatureControlTypeEnum.Toggle:
    case SubFeatureControlTypeEnum.Checkbox:
      component = getFleetDataOptions(featureId, value, result, subFeatureId);
      break;
    case SubFeatureControlTypeEnum.File:
      component = getFleetDataDefaultValue(value, result, SubFeatureControlTypeEnum.File);
      break;
    case SubFeatureControlTypeEnum.Table:
      component = getFleetDataTable(featureId, value, result, subFeatureId , deviceConstraints );
      break;
    case SubFeatureControlTypeEnum.IPconfigPrecedence:
      component = getFleetDataIPconfigPrecedence(featureId, value, result, subFeatureId);
    break;
    case SubFeatureControlTypeEnum.LowThreshold:
      component = getDeviceCacheCartridgeThreshold(featureId, value);
    break;
  }
  return (
    <Fragment>
      <Style.SubFeatureValue id={`${subFeatureId}-value`}>{component}</Style.SubFeatureValue>
    </Fragment>
  );
};
