import { Checkbox } from '@veneer/core';
import React, { memo, useContext, useEffect, useState } from 'react';
import { ConstraintsEffectEnum } from '../../config/ConstraintsEnums';
import { FeatureEffectivePolicyEnum } from '../../config/FeatureEnums';
import { SubFeatureAccessModeEnum } from '../../config/SubFeatureEnums';
import EditDataContext from '../../context/EditDataContext';
import LocalizeHelper from '../../helper/LocalizeHelper';
import TempDataHelper from '../../helper/TempDataHelper';
import { FullWidthInputWrap, InputWrap, InputWrapHeading } from './Styles';
import { InformationIcon } from '../CommonExtended/Icons/InformationIcon';

const CheckBoxControl = (props) => {
  const { device_settings, featuresId, isChecked, isControlledPolicy } = props;
  const { getDeviceSettingsLocString } = LocalizeHelper();
  const { getRulesEffect } = TempDataHelper();

  const { onUpdateTempData, tempRulesEffect } = useContext(EditDataContext);

  const checkboxFleetVal = device_settings.fleetValue
    ? device_settings.fleetValue !== '' && device_settings.fleetValue !== 'false'
      ? true
      : false
    : false;

  const [enabled, setEnabled] = useState(checkboxFleetVal);
  const [effectVal, setEffectVal] = useState();

  const label = getDeviceSettingsLocString(featuresId, device_settings.label);

  const isDisable =
    effectVal &&
    (effectVal === ConstraintsEffectEnum.ENABLE || effectVal === ConstraintsEffectEnum.SET)
      ? true
      : isChecked && isChecked
      ? isChecked
      : isControlledPolicy === FeatureEffectivePolicyEnum.controlledPolicy &&
        device_settings.accessMode === SubFeatureAccessModeEnum.READWRITE_DISABLE
      ? true
      : false;

  const tooltipMessage = getDeviceSettingsLocString(featuresId, device_settings.info);
  const tooltipMessageTwo = getDeviceSettingsLocString(featuresId, device_settings.info1);

  const handleOnchange = (value) => {
    setEnabled(value);

    onUpdateTempData({
      settingsName: device_settings.settingsName,
      fleetValue: value,
      attribute: device_settings.attribute,
      error: false,
    });
  };

  useEffect(() => {
    const ruleEffect = getRulesEffect(tempRulesEffect, device_settings.settingsName).effect;
    setEffectVal(ruleEffect);
  }, [tempRulesEffect]);

  return (
    <>
      {device_settings.heading ? (
        <InputWrapHeading>
          {getDeviceSettingsLocString(featuresId, device_settings.heading)}
        </InputWrapHeading>
      ) : null}

      <FullWidthInputWrap>
        <Checkbox
          id={device_settings.attribute}
          label={label}
          checked={enabled}
          disabled={isDisable}
          onChange={({ target }) => handleOnchange(target.checked)}
        />
        {tooltipMessage ? (
        <InformationIcon
          infoMessage={tooltipMessage}
          infoMessageTwo={tooltipMessageTwo}
          size={16}
          color="colorGray5"
          isDisabled={isDisable}
          {...props}
        />
      ) : null}
      </FullWidthInputWrap>
    </>
  );
};

export default memo(CheckBoxControl);