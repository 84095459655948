import React, { memo, useEffect, useState } from 'react';
import DropDownControl from './DropDownControl';
import TextBoxControl from './TextBoxControl';
import LocalizeHelper from '../../../helper/LocalizeHelper';

const OutGoingServerSplitEmail = (props) => {
  const { featuresId, device_settings, isControlledPolicy } = props;
  const { getDeviceConfigurationsLocString } = LocalizeHelper();

  const supportedOptions = device_settings.constraints.options.map((v) => {
    return {
      value: v.value / 1024,
      label: getDeviceConfigurationsLocString(featuresId, v.value, device_settings.id),
    };
  });

  const floatValue = parseFloat((device_settings.fleetValue / 1024).toString())
  const valueExists = supportedOptions.some(option => option.value == floatValue);

  const fleetValue = valueExists ? [floatValue] : ['custom'];
  const [splitEmailVal, setSplitEmailVal] = useState(fleetValue);
  const [splitEmailInputVal, setSplitEmailInputVal] = useState(valueExists ? '' : floatValue);

  return (
    <>
      <DropDownControl
        device_settings={device_settings}
        featuresId={featuresId}
        isControlledPolicy={isControlledPolicy}
        inputVal={splitEmailVal}
        splitEmailVal={splitEmailInputVal}
        setInputVal={setSplitEmailVal}
        setSplitEmailInputVal={setSplitEmailInputVal}
      />
      <TextBoxControl
        splitEmailVal={splitEmailVal}
        setSplitEmailInputVal={setSplitEmailInputVal}
        splitEmailInputVal={splitEmailInputVal}
        device_settings={device_settings}
      />
    </>
  );
};

export default memo(OutGoingServerSplitEmail);
