import { IconInfo, Tooltip } from '@veneer/core';
import React from 'react';
import { IconContainer, TooltipContainer } from './Styles';

export const InformationIcon = (props) => {
  const { infoMessage, infoMessageTwo, size, color, isDisabled, placement, subFeatureId } = props;
  return (
    <TooltipContainer>
      {infoMessage ? (
        isDisabled ? (
          <IconContainer id={`${subFeatureId}-info-icon`}>
            <IconInfo size={size} color={color} disabled={isDisabled} />
          </IconContainer>
        ) : (
          <Tooltip
            id={`${subFeatureId}-info-icon-message`}
            arrow
            content={<><div>{infoMessage}</div>{infoMessageTwo ? <div style={{ marginTop: "8px" }}>{infoMessageTwo}</div>: null}</>}
            placement={placement ? placement : 'bottom'}
            portal
            useJsPositioning
          >
            <IconContainer id={`${subFeatureId}-info-icon`}>
              <IconInfo size={size} color={color} disabled={isDisabled} />
            </IconContainer>
          </Tooltip>
        )
      ) : null}
    </TooltipContainer>
  );
};