import { FleetSvcCustomValue, FleetSvcPolicyAttributeId } from '../config/FleetServiceEnums';

export const FleetAttributeHelper = (item) => {
  const snmpAccessOptionValues = (_value) => {
    let value;
    switch (_value) {
      case 'readOnly':
        value = `${FleetSvcCustomValue.READONLY}`;
        break;
      case 'readWrite':
        value = `${FleetSvcCustomValue.READWRITE}`;
        break;
      case 'disable':
        value = `${FleetSvcCustomValue.DISABLE}`;
        break;
    }
    return value;
  };

  const encryptionAlgorithm = (_value) => {
    let value;
    switch (_value) {
      case 'md5':
        value = `${FleetSvcCustomValue.MD5}`;
        break;
      case 'sha1':
        value = `${FleetSvcCustomValue.SHA1}`;
        break;
    }
    return value;
  };

  const ldapSetupCredential = (_value) => {
    let value;
    switch (_value) {
      case 'true':
      case true:
        value = `${FleetSvcCustomValue.LDAP_SETUP_CREDENTIAL_TRUE}`;
        break;
      case 'false':
      case false:
        value = `${FleetSvcCustomValue.LDAP_SETUP_CREDENTIAL_FALSE}`;
        break;
    }
    return value;
  };

  const ldapBindSearchRoot = (_value) => {
    let value;
    // const result = JSON.stringify(_value);
    value = JSON.stringify(_value);
    return value;
  };

  const onAuthenticationFailure = (_value) => {
    let value;
    switch (_value) {
      case 'true':
      case true:
        value = `${FleetSvcCustomValue.DOT1X_AUTHENTICATION_ONAUTHENTICATIONFAILURE_TRUE}`;
        break;
      case 'false':
      case false:
        value = `${FleetSvcCustomValue.DOT1X_AUTHENTICATION_ONAUTHENTICATIONFAILURE_FALSE}`;
        break;
    }
    return value;
  };

  const outGoingServersMode = (_value) => {
    let value;
    switch (_value) {
      case 'append':
        value = `${FleetSvcCustomValue.OUTGOING_SERVERMODE_APPEND}`;
        break;
      case 'overwrite':
        value = `${FleetSvcCustomValue.OUTGOING_SERVERMODE_OVERRIDE}`;
        break;
    }
    return value;
  };

  // const outGoingServerSplitEmail = (_value) => {
  //   return _value;
  // }

  const appDeployment = (apps) => {
    const filteredValue = apps
    .filter(app => app.uuid && app.config)
    .map(app => ({
      uuid: app.uuid,
      value: app.config[0]
    })).filter(function (_result) {
      return _result.value !== undefined;
    });;

    value = JSON.stringify(filteredValue);
    return value;
  }

  let name, value;
  switch (item.name) {
    case `${FleetSvcPolicyAttributeId.SNMPV1V2_RW_Access}.permission`:
      name = item.name;
      value = snmpAccessOptionValues(item.value);
      break;
    case `${FleetSvcPolicyAttributeId.SNMPV3}.encryption-algorithm`:
      name = item.name;
      value = encryptionAlgorithm(item.value);
      break;
    case `${FleetSvcPolicyAttributeId.LDAP_Setup}.credential`:
      name = item.name;
      value = ldapSetupCredential(item.value);
      break;
    case `${FleetSvcPolicyAttributeId.LDAP_Setup}.roots`:
    case `${FleetSvcPolicyAttributeId.Outgoing_Server}.servers`:
      name = item.name;
      value = ldapBindSearchRoot(item.value);
      break;
    case `${FleetSvcPolicyAttributeId.Dot1x_802_1x_auth}.on-failure`:
      name = item.name;
      value = onAuthenticationFailure(item.value);
      break;
    case `${FleetSvcPolicyAttributeId.Outgoing_Server}.mode`:
      name = item.name;
      value = outGoingServersMode(item.value);
      break;
    case `${FleetSvcPolicyAttributeId.App_Deployment}.apps`:
      name = item.name;
      value = appDeployment(item.value);
      break;
    // case `${FleetSvcPolicyAttributeId.Outgoing_Server_Split}`:
    //   name = item.name;
    //   value = outGoingServerSplitEmail(item.value);
    //   break;
    default:
      name = item.name;
      value = item.value;
      break;
  }

  return { name, value };
};
