import { SubfeatureIdEnum } from '../../config/SubFeatureEnums';
import { EmailAddressMessageHelper } from '../../helper/table/EmailAddressMessageHelper';
import { LdapSigninSetupHelper } from '../../helper/table/LdapSigninSetupHelper';
import { OutgoingServersHelper } from '../../helper/table/OutgoingServersHelper';
import LocalizeHelper from '../../helper/LocalizeHelper';
import { SleepScheduleHelper } from '../../helper/table/SleepScheduleHelper';
import { AccessControlPermissionSetsHelper } from '../../helper/table/AccessControlPermissionSetsHelper';

const useTableBody = (id, subFeatureId, tableHead, value , deviceConstraints) => {
  let tableBody;
  const { getDeviceCommonLocString } =
    LocalizeHelper();

  const tableBodyMapping = {
    [SubfeatureIdEnum.EmailAddress_MessageSetting]: (value) =>
      EmailAddressMessageHelper(subFeatureId, value).emailAddressMessageData,
    [SubfeatureIdEnum.Outgoing_Server_List_Email]: (value) =>
      OutgoingServersHelper(subFeatureId, value).outgoingServersData,
    [SubfeatureIdEnum.LDAP_Bind_And_Search_Root]: (value) =>
      LdapSigninSetupHelper(subFeatureId, value).LdapSigninData,
    [SubfeatureIdEnum.Sleep_Schedule_Weekly]: (value) =>
      SleepScheduleHelper(subFeatureId, value).SleepScheduleData,
    [SubfeatureIdEnum.Sleep_Schedule_Holiday]: (value) =>
      SleepScheduleHelper(subFeatureId, value).SleepScheduleData,
    [SubfeatureIdEnum.PermissionSets]: (value) =>
      AccessControlPermissionSetsHelper(value, deviceConstraints, id),
  };

  const transformItemBasedOnSubFeatureId = (item, subFeatureId, id, getDeviceCommonLocString) => {
    if (subFeatureId === SubfeatureIdEnum.Non_Default_Applications || subFeatureId === SubfeatureIdEnum.Tray_Admin) {
      return Object.keys(item).reduce((transformedObject, key) => {
        const value = item[key];
        const localizedValue = getDeviceCommonLocString(
          id,
          subFeatureId === SubfeatureIdEnum.Tray_Admin ? String(key + '_' + value) : String(value)
        ) || String(value);
        transformedObject[key] = localizedValue;
        return transformedObject;
      }, {});
    }
    return item;
  };

  const getCommonTableBody = (valuex) => {
    return valuex.map((item) => {
      if (typeof item === 'object' && item !== null && !Array.isArray(item)) {
        return transformItemBasedOnSubFeatureId(item, subFeatureId, id, getDeviceCommonLocString);
      } else {
        return { [tableHead[0].id]: item };
      }
    });
  };

  tableBody = tableBodyMapping[subFeatureId]
    ? tableBodyMapping[subFeatureId](value)
    : getCommonTableBody(value);

  return { tableBody };
};

export default useTableBody;