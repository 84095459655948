import { TextBox } from '@veneer/core';
import React, { memo, useContext, useEffect, useState } from 'react';
import { InputLabel } from '../../../components/CommonExtended/InputTitle/InputLabel';
import EditDataContext from '../../../context/EditDataContext';
import { TextInputWrap } from './Styles';
import LocalizeHelper from '../../../helper/LocalizeHelper';

const TextBoxControl = (props) => {
  const { device_settings, splitEmailVal, setSplitEmailInputVal, splitEmailInputVal } = props;

  const { onUpdateTempData } = useContext(EditDataContext);

  const {
    getDeviceSettingsLocString,
    getRequirePasswordStringsWithRange,
  } = LocalizeHelper();

  const [error, setError] = useState('');
  const [errorMinRange, setErrorMinRange] = useState('');
  const [errorMaxRange, setErrorMaxRange] = useState('');

  const hint = getDeviceSettingsLocString("fe-outgoing-servers", "custom_split_email_hint")

  const errorMsg = getRequirePasswordStringsWithRange('error_range', errorMinRange, errorMaxRange);

  const handleOnchange = (value) => {
    const minimum = 0;
    const maximum = 999;
    let errorType = '';
    let errorMinRange;
    let errorMaxRange;
    const isInteger = /^[0-9]+$/;

    if (value && value.length && isInteger.test(value)) {
      if (minimum !== undefined) {
          if (value < minimum) {
            errorType = 'error_range';
            errorMinRange = minimum;
            errorMaxRange = maximum;
          }
      }
      if (maximum !== undefined) {
          if (value > maximum) {
            errorType = 'error_range';
            errorMinRange = minimum;
            errorMaxRange = maximum;
          }
      }

      setError(errorType);
      setErrorMinRange(errorMinRange);
      setErrorMaxRange(errorMaxRange);
    }


    if(!value || isInteger.test(value)){
    setSplitEmailInputVal(value);
      onUpdateTempData({
        settingsName: device_settings.settingsName,
        fleetValue: value ? value * 1024 : '',
        attribute: device_settings.attribute,
        error: value && !!errorType,
      });
    }
  };

  useEffect(() => {
    if (splitEmailVal[0] == 'custom') {
      handleOnchange(splitEmailInputVal);
    }
  }, [splitEmailVal]);

  return (
    <TextInputWrap>
      <TextBox
        id={''}
        helperText={error ? error && errorMsg : hint}
        placeholder={''}
        disabled={splitEmailVal[0] === 'custom' ? false : true}
        value={splitEmailInputVal}
        error={!!error}
        onChange={(value) => handleOnchange(value)}
      />
      <InputLabel title={'MB'} disable={splitEmailVal[0] === 'custom' ? false : true} />
    </TextInputWrap>
  );
};

export default memo(TextBoxControl);
